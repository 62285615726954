<template>
  <div class="head-title">
    <div class="left">
      <ul class="breadcrumb">
        <li>
          <a href="/HomeProperty" class="active">{{ $t("Property") }}</a>
        </li>
        <li>-</li>
        <li>
          <a class="active">{{ $t("Statement") }} {{ $t("due") }} {{ $t("Rentals") }}</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="main">
    <div class="head">
      <div class="head1">
        <span>{{ $t("realty") }}</span> &nbsp;&nbsp;
        <v-select :options="accounts" label="name" v-model="item"
          style="background-color: white; color: black; width: 30%">
          <template #option="option">
            <div style="text-align: center">
              <span style="color: black"> {{ option.name }}</span>
            </div>
          </template> </v-select>&nbsp;&nbsp;
        {{ $t("from") }}
        <input type="date" :placeholder="$t('from')" v-model="from" />
        {{ $t("to") }}
        <input type="date" :placeholder="$t('to')" v-model="to" />
        <button type="submit" @click="submit">
          <i class="bi bi-search"></i> {{ $t("search") }}
        </button>
      </div>
      <div class="head2">
        <!-- <button type="submit" @click="pdf">
          <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
        </button> -->
        <button type="submit" @click="exportToExcel">
          <i class="bi bi-file-earmark-excel"></i> Excel
        </button>
      </div>
    </div>

    <div class="body" v-if="this.data1.length == 0">
      <br />
      برجاء اختيار اسم العقار
    </div>
    <div class="body" v-if="this.data1.length > 0">
      <!-- <img :src="this.url" crossorigin="anonymous" /> -->
      <br />
      <div style="padding: 2%; direction: rtl; font-size: small">
        <h4>
          {{ $t("Statement") }} {{ $t("due") }} {{ $t("Rentals") }} -
          <span>{{ realty }}</span>
        </h4>
        <h6>{{ $t("from") }} : {{ fromdate }} {{ $t("to") }} : {{ todate }}</h6>

        <table class="table">
          <thead>
            <tr>
              <th>{{ $t("number") }} {{ $t("contract") }}</th>
              <th>{{ $t("name") }} {{ $t("realty") }}</th>
              <th>{{ $t("date") }} {{ $t("due") }}</th>
              <th>{{ $t("date") }} {{ $t("start") }} {{ $t("contract") }}</th>
              <th>{{ $t("date") }} {{ $t("end") }} {{ $t("contract") }}</th>
              <th>{{ $t("number") }} {{ $t("unit") }}</th>
              <th>{{ $t("name") }} {{ $t("renter") }}</th>

              <th>{{ $t("amount") }} {{ $t("due") }}</th>
              <th>{{ $t("print") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="i in data1" :key="i">
              <td>
                <router-link to="">{{ i.conid }}</router-link>
              </td>
              <td>{{ i.propertyname }}</td>
              <td>{{ i.duedate }}</td>
              <td>{{ i.contstart }}</td>
              <td>
                {{ i.contend }}
              </td>
              <td>
                {{ i.unite }}
              </td>
              <td>{{ i.rentername }}</td>

              <td>{{ i.dueamount }}</td>
              <td>
                <!-- Button trigger modal -->
                <button type="button" data-bs-toggle="modal" :data-bs-target="'#exampleModal' + i.conid"
                  @click="getinstallment(i.conid, i.duedate, i, i.dueamount)">
                  <i class="bi bi-printer-fill"></i>
                </button>

                <!-- Modal -->
                <div class="modal fade" :id="'exampleModal' + i.conid" tabindex="-1" aria-labelledby="exampleModalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          خطاب مطالبة
                        </h5>
                      </div>
                      <div class="modal-body" style="padding: 2%" id="main">
                        <div style="direction: rtl">
                          <div class="modhead">
                            <br /><br /><br /><br />
                            <div class="part">
                              الصادر : {{ id }} <br />
                              السادة : {{ rentername }}
                            </div>
                            <div class="logo">
                              <!-- <img
                                :src="this.url"
                                @error="
                                  $event.target.src = `${this.url}Default.png`
                                "
                                crossorigin="anonymous"
                              /> -->
                            </div>
                          </div>
                          <div style="padding: 2%">
                            السلام عليكم ورحمة الله وبركاتة <br />
                            تحية طيبة وبعد ,,,, <br />
                            الموضوع : مطالبة مالية <br />
                            إشارة الى العقد رقم {{ igarnumber }} المبرم معكم
                            لايجار وحدة رقم
                            {{ unite }}
                            {{ propertyname }} <br />
                            علية نفيدكم بالمستحق كما هو موضح بالجدول التالى
                          </div>
                          <div>
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>م</th>
                                  <th>النوع</th>
                                  <th>تاريخ الاستحقاق</th>
                                  <!-- <th>بدون الضريبة</th> -->
                                  <th>صافى الايجار</th>
                                  <th>ضريبة القيمة المضافة 15%</th>
                                  <th>الاجمالى</th>
                                  <th>المسدد</th>
                                  <!-- <th>مبلغ الاستحقاق</th>
                                  <th>ضريبة القيمة المضافة 15%</th> -->

                                  <!-- 
                                <th>رسوم الخدمات</th>
                                <th>ضريبة القيمة المضافة 15%</th> -->

                                  <th>اجمالى المستحق</th>

                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(y, id) in allinstallment" :key="y">
                                  <td>{{ id + 1 }}</td>
                                  <td>{{ $t(y.duetype) }}</td>
                                  <td>
                                    {{
                                      " الفترة من" +
                                      " " +
                                      y.from +
                                      " " +
                                      "الى " +
                                      y.to
                                    }}
                                  </td>
                                  <!-- <td>{{ y.amountwithout }}</td> -->
                                  <td><span>{{ Number(y.renteramount).toFixed(2) }}</span></td>
                                  <td><span>{{ Number(y.renteramounttax).toFixed(2) }}</span></td>
                                  <td><span>{{ (Number(y.renteramounttax) + Number(y.renteramount)).toFixed(2)
                                      }}</span></td>
                                  <td><span>{{ Number(y.dueamount - y.totalamount).toFixed(2) }}</span></td>

                                  <!-- <td>
                                    {{ y.amount }}
                                  </td>
                                  <td>
                                    {{ y.amounttax }}
                                  </td> -->


                                  <!-- <td>0</td>
                                <td>0</td> -->

                                  <td>{{ y.totalamount }}</td>

                                </tr>
                                <tr v-for="(y, id) in commission" :key="y">
                                  <td v-if="
                                    Number(y.amount - y.paied).toFixed(2) != 0
                                  ">
                                    {{ id + 1 + this.allinstallment.length }}
                                  </td>
                                  <td v-if="
                                    Number(y.amount - y.paied).toFixed(2) != 0
                                  ">
                                    {{ y.name }}
                                  </td>
                                  <td v-if="
                                    Number(y.amount - y.paied).toFixed(2) != 0
                                  ">
                                    عن الفترة من تاريخ
                                    {{ y.duedate }}
                                  </td>

                                  <!-- <td v-if="
                                    Number(y.amount - y.paied).toFixed(2) != 0
                                  ">
                                    {{ y.amount }}
                                  </td> -->
                                  <td v-if="
                                    Number(y.amount - y.paied).toFixed(2) != 0
                                  ">
                                    {{ y.comamount }}
                                  </td>
                                  <td v-if="
                                    Number(y.amount - y.paied).toFixed(2) != 0
                                  ">
                                    {{ ((y.comamount * 15) / 100).toFixed(2) }}
                                  </td>
                                  <td></td>
                                  <td v-if="Number(y.amount - y.paied).toFixed(2) != 0"><span v-if="y.paied > 0"> {{
                                    Number(y.paied).toFixed(2) }}</span></td>

                                  <td v-if="
                                    Number(y.amount - y.paied).toFixed(2) != 0
                                  ">
                                    {{ Number(y.amount - y.paied).toFixed(2) }}
                                  </td>

                                  <!-- <td>{{ Number(y.taxamount).toFixed(2) }}</td> -->
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colspan="2">{{ $t("total") }}</td>
                                  <td colspan="4">{{ textamount }}</td>
                                  <td>{{ totaldue }}</td>
                                </tr>
                              </tfoot>
                            </table>
                            <br />
                            <!-- <h5>العمولات</h5>
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>العمولة</th>
                                  <th>نسبة العمولة</th>
                                  <th>مبلغ العمولة</th>
                                  <th>ضريبة العملة</th>
                                  <th>المبلغ</th>
                                  <th>مدفوع</th>
                                  <th>المتبقى</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="y in commission" :key="y">
                                  <td>{{ y.name }}</td>
                                  <td>{{ y.num }} %</td>
                                  <td>{{ y.comamount }}</td>
                                  <td>{{ y.tax }} %</td>
                                  <td>
                                    {{ y.amount }}
                                  </td>
                                  <td>{{ y.paied }}</td>
                                  <td>{{ y.amount - y.paied }}</td>
                                </tr>
                              </tbody>
                            </table> -->
                            وهذا الخطاب بالمبلغ المستحق عليكم لذا نأمل سرعة
                            السداد وإصدار شيك باسم /
                            {{ this.$store.state.cnum[0].cname }}
                            العقاري او الايداع فى احد حساباتنا رقم : <br />
                            SA0980000101608013332224 مصرف الراجحى <br />
                            SA1305000068201333222000 مصرف الانماء <br />
                            SA7215000427122878050001 بنك البلاد <br /><br />
                            ولكم جزيل الشكر ,,,,,
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                          {{ $t("cancel") }}
                        </button>
                        <button type="button" class="btn btn-primary" @click="pdf">
                          <i class="bi bi-printer-fill"></i> {{ $t("print") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="buttom">
      <button class="buttonconfirm" type="submit" v-if="this.view != 0" @click="pdf">
        <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import vSelect from "vue-select";
import html2pdf from "html2pdf.js";
import store from "@/store";
var tafqeet = require("../../TafqeetJs-master/Tafqeet");
import * as XLSX from "xlsx";
export default {
  components: {
    vSelect,
  },
  async mounted() {
    const result2 = await axios.get("/data/getallproperty");
    if (result2.status == 200) {
      const accounts = result2.data.data;
      for (let i = 0; i < accounts.length; i++) {
        this.accounts.push({ name: accounts[i].name, pid: accounts[i].pid });
      }
    }
    this.url =
      store.state.imgurl + `/clogo/${this.$store.state.cnum[0].cnum}.png`;
  },
  props: ["css"],
  data() {
    return {
      item: {},
      fromdate: "",
      todate: "",
      data1: [],
      allinstallment: [],
      view: "",
      namear: "",
      nameen: "",
      typeact: "",
      accounts: [{ name: "كل العقارات", pid: "all" }],
      realty: "",
      totaldue: 0,
      textamount: "",
      commission: [],
      conttype: "",
      id: "",
      rentername: "",
      unite: "",
      propertyname: "",
      igarnumber: "",
      contstart: "",
      comend: "",
    };
  },
  methods: {
    async submit() {
      if (this.from && this.to) {
        const result = await axios.get(
          `/reports/rentalstatement/${this.from}/${this.to}/${this.item.pid}`
        );
        if (result.status == 200) {
          this.data1 = result.data.data;
          this.realty = this.item.name;
          this.fromdate = this.from;
          this.todate = this.to;
        }
      }
    },
    async getinstallment(id, from, i, dueamount) {
      const result2 = await axios.get(
        `/reports/getcontractnumber/${id}/${i.id}`
      );
      this.igarnumber = result2.data.data[0];
      this.id = result2.data.data[1];
      this.rentername = i.rentername;
      this.unite = i.unite;
      this.propertyname = i.propertyname;
      this.contstart = i.contstart;
      (this.comend = moment(this.contstart).add(1, "Y").format("YYYY-MM-DD")),
        (this.allinstallment = []);
      this.totaldue = 0;
      const result = await axios.get(
        `/reports/getinstallment/${from}/${from}/${id}`
      );

      if (result.status == 200) {
        const data = result.data.data;
        this.conttype = result.data.data[0].conttype;
        for (let i = 0; i < data.length; i++) {
          if (this.conttype == "commercial") {
            if (Math.abs(data[i].change) > 0) {
              const amount = Math.abs(data[i].change);
              this.allinstallment.push({
                duetype: data[i].duetype,
                amountwithout: data[i].dueamount,
                amount: Number(amount / 1.15).toFixed(2),
                amounttax: Number(((amount / 1.15) * 15) / 100).toFixed(2),
                from: data[i].duedate,
                to: moment(data[i].duedate)
                  .add(data[0].instdue, "M")
                  .format("YYYY-MM-DD"),
                totalamount: Number(amount).toFixed(2),
                dueamount: dueamount,
                renteramount: dueamount / 1.15,
                renteramounttax: Number(((dueamount / 1.15) * 15) / 100).toFixed(2),
              });
            } else {
              const amount = data[i].dueamount;
              this.allinstallment.push({
                duetype: data[i].duetype,
                amount: Number(amount / 1.15).toFixed(2),
                amounttax: Number(((amount / 1.15) * 15) / 100).toFixed(2),
                from: data[i].duedate,
                to: moment(data[i].duedate)
                  .add(data[0].instdue, "M")
                  .format("YYYY-MM-DD"),
                totalamount: Number(amount).toFixed(2),
                dueamount: dueamount,
                renteramount: dueamount / 1.15,
                renteramounttax: Number(((dueamount / 1.15) * 15) / 100).toFixed(2),
              });
            }
          }
          if (this.conttype == "residential") {
            if (Math.abs(data[i].change) > 0) {
              const amount = Math.abs(data[i].change);
              this.allinstallment.push({
                duetype: data[i].duetype,
                amountwithout: data[i].dueamount,
                amount: Number(amount).toFixed(2),
                amounttax: 0,
                from: data[i].duedate,
                to: moment(data[i].duedate)
                  .add(data[0].instdue, "M")
                  .format("YYYY-MM-DD"),
                totalamount: Number(amount).toFixed(2),
                dueamount: dueamount,
                renteramount: dueamount,
                renteramounttax: 0,
              });
            } else {
              const amount = data[i].dueamount;
              this.allinstallment.push({
                duetype: data[i].duetype,
                amount: Number(amount).toFixed(2),
                amounttax: 0,
                from: data[i].duedate,
                to: moment(data[i].duedate)
                  .add(data[0].instdue, "M")
                  .format("YYYY-MM-DD"),
                totalamount: Number(amount).toFixed(2),
                dueamount: dueamount,
                renteramount: dueamount,
                renteramounttax: 0,
              });
            }
          }
        }
        const result3 = await axios.get(`/reports/getallcommissions/${id}`);
        if (result3.status == 200) {
          this.commission = result3.data.data;
        }
        const one = this.allinstallment
          .reduce((acc, array) => acc + Number(array.totalamount), 0)
          .toFixed(2);
        const two = this.commission
          .reduce((acc, array) => acc + Number(array.amount), 0)
          .toFixed(2);
        const three = this.commission
          .reduce((acc, array) => acc + Number(array.paied), 0)
          .toFixed(2);

        this.totaldue = Number(
          Number(one) + Number(two) - Number(three)
        ).toFixed(2);

        var fraction = this.totaldue.split(".");
        if (fraction.length == 2) {
          this.textamount =
            tafqeet.tafqeet(fraction[0]) +
            " " +
            "ريال" +
            " و " +
            tafqeet.tafqeet(fraction[1]) +
            " هلله " +
            " فقط ";
          if (Number(fraction[1]) == 0) {
            this.textamount =
              tafqeet.tafqeet(fraction[0]) + " " + "ريال" + " " + " فقط ";
          }
        }
        if (fraction.length == 1) {
          this.textamount =
            tafqeet.tafqeet(fraction[0]) +
            " " +
            "ريال" +
            " و " +
            tafqeet.tafqeet(fraction[1]) +
            " هلله " +
            " فقط ";
          this.textamount =
            tafqeet.tafqeet(fraction[0]) + " " + "ريال" + " " + "فقط";
        }
      }
      const result3 = await axios.get(`/reports/getallcommissions/${id}`);
      if (result3.status == 200) {
        this.commission = result3.data.data;
      }
    },
    exportToExcel() {
      var data = [];
      for (let i = 0; i < this.data1.length; i++) {
        const name = this.data1[i];
        data.push({
          رقم_العقد: name.conid,
          اسم_العقار: name.propertyname,
          تاريخ_الاستحقاق: name.duedate,
          تاريخ_بدا_العقد: name.contstart,
          تاريخ_نهاية_العقد: name.contend,
          رقم_الوحدة: name.unite,
          اسم_المستاجر: name.rentername,
          مبلغ_الاستحقاق: name.dueamount,
        });
      }
      // Create a new workbook
      const wb = XLSX.utils.book_new();
      // Convert data to a worksheet
      const ws = XLSX.utils.json_to_sheet(data);
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // Generate a download link to the Excel file
      XLSX.writeFile(wb, `statement${this.item.name}.xlsx`);
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.5,
        filename: `استحقاق.pdf`,
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    print2() {
      this.$htmlToPaper("main");
    },
  },
  watch: {
    item() {
      this.data1 = [];
      this.from = "";
      this.to = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
  display: flex;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.modhead {
  display: flex;
}

.modhead .part {
  width: 75%;
  padding-top: 5%;
  font-size: large;
}

.modhead .logo {
  width: 25%;
}

.modhead img {
  width: 100%;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
