<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("management") }} {{ $t("projects") }}</a>
          </li>
          <li>-</li>
          <li>
            <router-link to="/property/allprojects" class="active">{{ $t("all") }}
              {{ $t("projects") }}</router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>{{ $t("projects") }} </h3>
          <input type="text" :placeholder="$t('name') + ' ' + $t('project')" v-model="realtyname"
            @keypress.enter="filter" />
          <input type="text" :placeholder="$t('name') + ' ' + $t('owner')" v-model="ownername"
            @keypress.enter="filter2" />
          <!-- <input type="text" :placeholder="$t('mobile')" /> -->
        </div>
        <div class="head2">
          <button>
            <router-link to="/property/createproject" class="btn-download">
              <span class="text">
                <i class="bi bi-clipboard-plus-fill"></i>
                {{ $t("add") }}
                {{ $t("project") }}</span>
            </router-link>
          </button>
        </div>
      </div>
      <div class="body">
        <!-- <router-link class="btn-download" v-for="x in property" :key="x" :to="link + x.pid">
          <div class="part">
            <div class="group">
              <div style="text-align: center">{{ x.name }}</div>
              <div style="font-size: small">
                {{ $t("owner") }} : {{ x.ownername }}
              </div>
            </div>
            <div class="group">
              <img :src="this.url + x.pid + '-' + this.cnum + '.png'"
                @error="$event.target.src = `${this.url}Default.png`" crossorigin="anonymous" />
            </div>
          </div>
        </router-link> -->

        <table class="table">
          <thead>
            <tr>
              <th>{{ $t("code") }}</th>
              <th>{{ $t("name") }} {{ $t("project") }}</th>
              <th>{{ $t("name") }} {{ $t("owner") }}</th>
              <th>{{ $t("type") }} {{ $t("project") }}</th>
              <th>{{ $t("numbers") }} {{ $t("contracts") }}</th>
              <th>{{ $t("amount") }} {{ $t("contracts") }}</th>
              <th>{{ $t("units") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="x in property" :key="x">
              <th>
                <router-link :to="link + x.pid">
                  {{ x.pid }}
                </router-link>
              </th>
              <th>
                <router-link :to="link + x.pid">{{ x.name }}</router-link>
              </th>
              <th>
                <router-link :to="link + x.pid">{{ x.ownername }}</router-link>
              </th>
              <td>{{ $t(x.catitem) }}</td>
              <td>{{ x.count }}</td>
              <td>{{ Number(x.amount).toLocaleString() }}</td>
              <td>{{ x.prodata }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
// import Swal from "sweetalert2";
import { mapActions } from "vuex";

export default {
  props: ["css"],
  components: {},
  data() {
    return {
      cnum: store.state.cnum[0].cnum,
      property: [],
      property2: [],
      suppliers: 0,
      invoices: 0,
      bills: 0,
      salles: "",
      url: "",
      link: "/property/propertydetails/",
      realtyname: "",
      ownername: "",
    };
  },
  async mounted() {
    const result = await axios.get("/data/getallpropertydetails");
    if (result.status == 200) {
      this.property = result.data.data;
      this.property2 = result.data.data;
    }
    // this.url = store.state.imgurl + `/property/`;
  },
  async created() { },
  methods: {
    filter() {
      this.property = this.property2.filter((x) =>
        x.name.includes(this.realtyname)
      );
    },
    filter2() {
      this.property = this.property2.filter((x) =>
        x.ownername.includes(this.ownername)
      );
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}

.body {
  padding: 1%;
  // min-height: 500px;
  // border: 1px solid black;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.part {
  font-size: large;
  width: 99%;
  padding: 2%;
  margin: 0.5%;
  background-color: white;
  border-radius: 5px;
  color: black;
  display: flex;
  // border: 1px solid black;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.part .group {
  width: 50%;
  //   border: black 1px solid;
}

.part img {
  width: 100%;
  height: 100px;
  //   border: black 1px solid;
}

ul {
  text-decoration: none;
  list-style-type: none;
  color: black;
}

.bi {
  margin: 1%;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 60%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 40%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
  font-size: small;
  width: 30%;
}

.head2 button a {
  color: white;
}
</style>
